import { default as indexVdS9zMbEBMMeta } from "/home/niko/repo/manuscrape_nuxt/pages/index.vue?macro=true";
import { default as loginHqbubZsrtFMeta } from "/home/niko/repo/manuscrape_nuxt/pages/login.vue?macro=true";
import { default as logouta5YsGA69f8Meta } from "/home/niko/repo/manuscrape_nuxt/pages/logout.vue?macro=true";
import { default as draftsD487amNQK2Meta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/drafts.vue?macro=true";
import { default as indexabKndpnNvtMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/index.vue?macro=true";
import { default as edit_45imageuZhLx3Hep7Meta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/edit-image.vue?macro=true";
import { default as indexVXK3ElNjzUMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/index.vue?macro=true";
import { default as index6l02EWLtnVMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/index.vue?macro=true";
import { default as indexSz8WcgVc4kMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/index.vue?macro=true";
import { default as newj8CgDNWS04Meta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/new.vue?macro=true";
import { default as indexlaDKmc6rnnMeta } from "/home/niko/repo/manuscrape_nuxt/pages/user/index.vue?macro=true";
import { default as newjdWiLNRbw5Meta } from "/home/niko/repo/manuscrape_nuxt/pages/user/new.vue?macro=true";
export default [
  {
    name: indexVdS9zMbEBMMeta?.name ?? "index",
    path: indexVdS9zMbEBMMeta?.path ?? "/",
    meta: indexVdS9zMbEBMMeta || {},
    alias: indexVdS9zMbEBMMeta?.alias || [],
    redirect: indexVdS9zMbEBMMeta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginHqbubZsrtFMeta?.name ?? "login",
    path: loginHqbubZsrtFMeta?.path ?? "/login",
    meta: loginHqbubZsrtFMeta || {},
    alias: loginHqbubZsrtFMeta?.alias || [],
    redirect: loginHqbubZsrtFMeta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logouta5YsGA69f8Meta?.name ?? "logout",
    path: logouta5YsGA69f8Meta?.path ?? "/logout",
    meta: logouta5YsGA69f8Meta || {},
    alias: logouta5YsGA69f8Meta?.alias || [],
    redirect: logouta5YsGA69f8Meta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: draftsD487amNQK2Meta?.name ?? "projects-projectId-drafts",
    path: draftsD487amNQK2Meta?.path ?? "/projects/:projectId()/drafts",
    meta: draftsD487amNQK2Meta || {},
    alias: draftsD487amNQK2Meta?.alias || [],
    redirect: draftsD487amNQK2Meta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/drafts.vue").then(m => m.default || m)
  },
  {
    name: indexabKndpnNvtMeta?.name ?? "projects-projectId",
    path: indexabKndpnNvtMeta?.path ?? "/projects/:projectId()",
    meta: indexabKndpnNvtMeta || {},
    alias: indexabKndpnNvtMeta?.alias || [],
    redirect: indexabKndpnNvtMeta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: edit_45imageuZhLx3Hep7Meta?.name ?? "projects-projectId-observations-observationId-edit-image",
    path: edit_45imageuZhLx3Hep7Meta?.path ?? "/projects/:projectId()/observations/:observationId()/edit-image",
    meta: edit_45imageuZhLx3Hep7Meta || {},
    alias: edit_45imageuZhLx3Hep7Meta?.alias || [],
    redirect: edit_45imageuZhLx3Hep7Meta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/edit-image.vue").then(m => m.default || m)
  },
  {
    name: indexVXK3ElNjzUMeta?.name ?? "projects-projectId-observations-observationId",
    path: indexVXK3ElNjzUMeta?.path ?? "/projects/:projectId()/observations/:observationId()",
    meta: indexVXK3ElNjzUMeta || {},
    alias: indexVXK3ElNjzUMeta?.alias || [],
    redirect: indexVXK3ElNjzUMeta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/index.vue").then(m => m.default || m)
  },
  {
    name: index6l02EWLtnVMeta?.name ?? "projects-projectId-observations",
    path: index6l02EWLtnVMeta?.path ?? "/projects/:projectId()/observations",
    meta: index6l02EWLtnVMeta || {},
    alias: index6l02EWLtnVMeta?.alias || [],
    redirect: index6l02EWLtnVMeta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/index.vue").then(m => m.default || m)
  },
  {
    name: indexSz8WcgVc4kMeta?.name ?? "projects",
    path: indexSz8WcgVc4kMeta?.path ?? "/projects",
    meta: indexSz8WcgVc4kMeta || {},
    alias: indexSz8WcgVc4kMeta?.alias || [],
    redirect: indexSz8WcgVc4kMeta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: newj8CgDNWS04Meta?.name ?? "projects-new",
    path: newj8CgDNWS04Meta?.path ?? "/projects/new",
    meta: newj8CgDNWS04Meta || {},
    alias: newj8CgDNWS04Meta?.alias || [],
    redirect: newj8CgDNWS04Meta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/new.vue").then(m => m.default || m)
  },
  {
    name: indexlaDKmc6rnnMeta?.name ?? "user",
    path: indexlaDKmc6rnnMeta?.path ?? "/user",
    meta: indexlaDKmc6rnnMeta || {},
    alias: indexlaDKmc6rnnMeta?.alias || [],
    redirect: indexlaDKmc6rnnMeta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: newjdWiLNRbw5Meta?.name ?? "user-new",
    path: newjdWiLNRbw5Meta?.path ?? "/user/new",
    meta: newjdWiLNRbw5Meta || {},
    alias: newjdWiLNRbw5Meta?.alias || [],
    redirect: newjdWiLNRbw5Meta?.redirect,
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/user/new.vue").then(m => m.default || m)
  }
]